import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import colors from "@styles/variables/officecubiclequotesVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";

export default function MainBanner(): ReactElement {
    const { visitDetails, domain } = useDomainContext();
    const router = useRouter();

    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={`px-4vw lg:px-4 ${styles["main-banner"]}`}>
                    <Form
                        classes={{
                            formClassName: styles["form"],
                            stepTitleClassName: styles["step-title"],
                            stepClassName: styles["step-block"],
                            stepDescriptionClassName:
                                styles["step-description"],
                            formButtonClassName: styles["form-button"],
                            formFieldClassName: styles["form-field"],
                            formStepInnerClassName: styles["steps-inner"],
                            fieldsClassName: {
                                radio: styles["radio-field"],
                                checkbox: styles["checkbox-field"],
                            },
                            fieldsWrapperClassName: styles["fields-wrapper"],
                            tcpaClassName: styles["tcpa"],
                            labelAsTitleClassName:
                                styles["label-as-step-title"],
                            sellingPointClassName: styles["selling-point"],
                            formProgressbarClassName: styles["custom-progress"],
                        }}
                        colors={{
                            primaryColor: colors.primary,
                            progressBar: colors.progress,
                        }}
                        showProgress={true}
                        sellingPoint={
                            visitDetails?.region
                                ? `${visitDetails?.region} Best Office Cubicles`
                                : ""
                        }
                        sellingPointsSteps="FIRST_STEP"
                        preventWindowClose
                    />
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
